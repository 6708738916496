import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OpenPartyIcon } from "styles/searchResult/SearchResultsItem.styled";
import { 
  AccordionActionButton,
  FundManagerItemLeftArea,
  FundManagerItemRightArea,
  RightIcons,
  CloseAccordionIcon,
  OpenAccordionIcon,
  FundsManagerContainer, 
  FundManagerName
} from "styles/funds/FundManagerList.styled";
import { Dispatch, SetStateAction } from "react";
import useFilters from "hooks/useFilter";
import { InstrumentsManagerType } from "types/party";
import ManagedFundsTable from "components/party/funds/ManagedFundsTable";
import { FundType } from "types/funds";

export function FundManagerList({
  data, 
  fundsToAdd,
  setFundsToAdd,
  setAnyFundSelected,
  toggleAll,
  searchStr,
  fundManagers,
  clientId,
  allSelected,
  anyFundSelected
}:{
  data: InstrumentsManagerType,
  fundsToAdd: {[key: string] : {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string}[]}[]},
  setFundsToAdd: Dispatch<SetStateAction<{[key: string] : {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string}[]}[]}>>,
  setAnyFundSelected: Dispatch<SetStateAction<boolean>>,
  toggleAll: boolean,
  searchStr?: string,
  fundManagers : InstrumentsManagerType[],
  clientId: string | undefined,
  allSelected: boolean,
  anyFundSelected: boolean
}) {
  const [showItem, setShowItem] = useState(true);
  const [funds, setFunds]= useState<FundType[]>(data["Instruments List"]);
  const { secondSetOfFilteredData, setFilter } = useFilters(fundManagers, funds);
  useEffect(()=>{
    setFilter({ secondSearch: searchStr });
  },[searchStr])

  useEffect(()=>{
    if(allSelected === true) {      
      setFunds((prevState)=>{
        const newState= [...prevState];
        newState.forEach((fund)=>fund.selected= true);
        return newState;
      })
    } else if(allSelected === false) {
      setFunds((prevState)=>{
        const newState= [...prevState];
        newState.forEach((fund)=>fund.selected= false);
        return newState;
      })
    }
  }, [allSelected])

  useEffect(()=>{
    if(!toggleAll) {
      setShowItem(false);
    } else {
      setShowItem(true);
    }
  },[toggleAll])

  return (
    <FundsManagerContainer> 
      <FundManagerItemLeftArea onClickCapture={() => setShowItem(true)}>
        <FundManagerName>{data["Legal Name [PID]"]}</FundManagerName> 
        <RightIcons>
          <AccordionActionButton>
            <Link to={`/funds/${data.PID}`} target="_blank" rel="noreferrer">
              <OpenPartyIcon
                  title={"Open Funds Profile"}
              />
            </Link>
          </AccordionActionButton>
          <AccordionActionButton onClick={() => setShowItem(!showItem)}>
            {showItem === true ? (
              <CloseAccordionIcon title="Close this result" />
            ) : (
              <OpenAccordionIcon title="Open this result" />
            )}
          </AccordionActionButton>
        </RightIcons>
      </FundManagerItemLeftArea>
      <FundManagerItemRightArea>
        {showItem && 
          <ManagedFundsTable 
            partyId={data.PID} 
            onboarding 
            fundsToAdd={fundsToAdd}
            setFundsToAdd={setFundsToAdd} 
            clientId={clientId}  
            setAnyFundSelected={setAnyFundSelected}
            anyFundSelected= {anyFundSelected}
            secondSetOfFilteredData= {secondSetOfFilteredData}
            allSelected={allSelected}
            setFundsFromParent= {setFunds}
          />
        }
      </FundManagerItemRightArea>
    </FundsManagerContainer>
  );
}
