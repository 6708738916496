import styled from "styled-components";

const elipsisIcon = `${process.env.PUBLIC_URL}/assets/elipsis.svg`;
const acceptedIcon = `${process.env.PUBLIC_URL}/assets/confirmed.svg`;

export const OnboardingMainContainer = styled.div<{
  noShadow?: boolean;
  noMarginTop?: boolean;
}>`
  margin-top: 0;
  display: block;
  column-span: all;
  margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
  margin-bottom: 60px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 8px;
  padding: 12px 0;
  padding-bottom: 37px;
  white-space: nowrap;
  overflow: auto;
  &:last-child {
    margin-bottom: 0px;
  }
  &:first-child {
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
  }
  margin-left: 22px;
`;

export const CirclesChartContainer = styled.div<{ boxShadow?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 12px;
`;

export const CirclesTitle = styled.div`
  width: 100%;
  font-size: 20px;
  margin-bottom: 20px;
`;

export const BarChartHolder = styled.div`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ProgressBar = styled.div`
  width: 80%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10%;
`;

export const BarChartContainer = styled.div<{boxShadow?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 12px;
`;

export const OptionsHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: right;
  margin-left: 20px;
`;

export const OptionToAdd = styled.div`
  background-color: #EDEFEF;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  width: min-content;
  align-self: end;
  margin-right: 15px;
`;

export const ElipsisHolder = styled.div`
  background: url(${elipsisIcon});
  background-size: cover;
  width: 12px;
  height: 24px;
  cursor: pointer;
  align-self: center;
`

export const BarChartTitle = styled.div`
  width: 100%;
  font-size: 20px;
  margin-bottom: 20px;
`;



export const CirclesChartHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 1097px) {
    grid-template-columns: repeat(2, 1fr);
  }
  width: 100%;
  margin-top: 30px;
`;

export const TopContainer = styled.div`
  width: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export const TopBottomContainer = styled.div`
  width: 92%;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 769px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const AccordionHolder= styled.div`
  width: 92%;
`;

export const BottomContainer = styled.div`
  width: 92%;
  margin-left: 10px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

export const BottomNavContainer = styled.div`
  max-width: 100%;
  margin: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #4A82A6;
  padding: 15px;
`;

export const BottomCompContainer = styled.div`
  width: 96%;
  margin: 0;
  padding: 12px;
`;

export const BottomNavItem = styled.div<{ isActive?: boolean}>`
  width: 11%;
  @media (max-width: 769px) {
    width: 45%;
  }
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.isActive ? "#fff" : "#d2d4d6"};
  border-bottom: ${(props) => props.isActive ? "5px solid #fff" : "none"};
  border-bottom-radius: 5px;
  cursor: pointer;
  text-wrap: wrap;
  margin-right: 50px;
`;

export const BottomContentContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  @media (max-width: 769px) {
    justify-content: start;
  }
`;

export const SearchWrapper = styled.div`
  width: 290px;
`;

export const TableContainer = styled.div`
  max-width: 100%;
  margin-top:30px;
  padding: 10px 15px;
`;

export const T360Actioned = styled.div`
  width: 24px;
  height: 24px;
  background-size: contain;
  background-image: url(${acceptedIcon});
`;

export const NotT360Actioned = styled.div`
  width: 24px;
  height: 24px;
`;

export const Th = styled.th`
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  font-weight: 600;
  padding: 0 24px;
  padding-bottom: 8px;
  text-wrap: nowrap;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const WrapTable = styled.div`
  margin-top: 25px;  
  white-space: nowrap;
  display: block;
  overflow: auto;
  max-height: 450px;
`;


export const RespTable = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
  // word-break: break-word;
`;

export const RespTableHeader = styled.div`
  display: table-header-group;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  background-color: rgba(224, 226, 236);
  word-break: break-word; // this is a temporary fixd for table width
  position: sticky;
  top: 0;
`;
export const TableHeaderCell = styled.div`
  display: table-cell;
  padding: 18px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
`;

export const RespTableBody = styled.div`
  display: table-row-group;
  padding: 0 40px;
`;

export const RespTableRow = styled.div<{ hasBorder?: boolean }>`
  display: table-row;
  border-bottom: ${(props) => (props.hasBorder ? "1px solid rgba(74, 130, 166, 0.5)" : "none")};
  font-size: 16px;
`;

export const TableBodyCell = styled.div`
  display: table-cell;
  padding: 18px;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 16px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 20px !important;
  }
  color: ${({ theme }) => (theme.colors.WHITE !== "#FFFFFF" ? "#fff" : theme.colors.DARK[400])};
  & a {
    color: inherit;
    &:visited {
      color: inherit;
    }
    &:hover {
      color: #364073;
      opacity: 0.9;
    }
  }
`;

export const TableContentContainer = styled.div<{
    noShadow?: boolean;
    noMarginTop?: boolean;
  }>`
    display: block;
    column-span: all;
    overflow-x: auto;
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    margin-bottom: 60px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: 8px;
    padding: 12px 0;
    padding-bottom: 37px;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0px;
    }
    &:first-child {
      margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    }
  `;
